/* TutorialPage.css */
.video-responsive {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: #000;
  }
  
  .video-responsive iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .card-body {
    padding: 2rem;
    width: auto;
    background: white;
  }
  
  .card {
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    width: auto;
    background: white;
  }
  
  .container {
    display: grid;
    justify-content: center;
    align-items: center;
    background: white;
  }
  
  .mb-5 {
    font-size: 1.225rem;
    margin-top: 20px;
    margin-left: -40px;
  }
  
  .navbar {
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    font-size: 1.125rem;
    margin-left: 0px;
    width: max-content;
    justify-content: center;
    align-items: center;
  }
  
  h2 {
    color: #007bff;
    font-size: 1.5em;
    white-space: nowrap;
    padding-top: 20px;
  }
  
  @media (max-width: 767px) {
    div#root {
      width: auto;
      background: white;
    }
  
    .mb-5 {
      margin-bottom: 2rem !important;
    }
    .nav-pills.navbar-nav {
      margin-left: 50px;
    }
  
    .fs-4 {
      font-size: calc(1.275rem + .3vw) !important;
      margin-left: 20px;
    }
    .col {
      flex: 1 0;
      margin-top: -80px;
    }
    .card-body {
      padding: 2rem;
      width: auto;
      background: white;
    }
    .card {
      border-radius: 10px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      width: auto;
      padding: 0.6em;
      background: white;
    }
    .container {
      display: grid;
      justify-content: center;
      align-items: center;
      background: white;
      margin-top: 5px;
      margin-left: 5px;
      width: auto;
    }
    .fade.modal.show {
      width: auto;
    }
  
    .modal-title {
      margin-bottom: 0;
      line-height: var(--bs-modal-title-line-height);
      width: 100%;
    }
    .modal-content {
      padding: 20px;
      width: auto;
      overflow-x: auto;
    }
  
    .modal-body {
      position: relative;
      flex: 1 1 auto;
      padding: var(--bs-modal-padding);
      width: max-content;
      overflow-x: auto;
    }
    .modal-backdrop.show {
      opacity: 0;
    }
    .img-fluid {
      max-width: 100%;
      height: auto;
    }
  
    svg.svg-inline--fa.fa-arrow-right.scroll-icon {
      margin-left: 250px;
      height: 49px;
      width: 100px;
      color: black;
    }
    .card-header {
      padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
      margin-bottom: 0;
      color: var(--bs-card-cap-color);
      background: white;
      border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
      width: 250px;
    }
  
    .page-title.mb-4.card-title.h5 {
      margin-left: -40px;
    }
  
    .img-thumbnail {
      padding: .25rem;
      background-color: var(--bs-body-bg);
      border: var(--bs-border-width) solid var(--bs-border-color);
      border-radius: var(--bs-border-radius);
      max-width: 200px;
      max-height: 200px;
      margin-left: 0;
    }
    .modal-dialog.modal-dialog-centered {
      width: 430px;
      margin-left: -10px;
      background: white;
    }
  
    .modal-header {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      padding: var(--bs-modal-header-padding);
      border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
      border-top-left-radius: var(--bs-modal-inner-border-radius);
      border-top-right-radius: var(--bs-modal-inner-border-radius);
      width: auto;
    }
  
    .navbar {
      border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
      font-size: 1.125rem;
      margin-left: 0px;
      width: max-content;
      justify-content: center;
      align-items: center;
      gap: 100px;
    }
  
    .page-title.mb-4.card-title.h5 {
      margin-left: -40px;
      font-size: larger;
      width: fit-content;
    }
  }
  