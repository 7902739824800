.container {
    display: flow;
    justify-content: center;
    align-items: center;
    
    
    background-color: #f8f9fa;

    @media (max-width: 767px) {
    width: max-content;
    margin-left: -10px;
    margin-top: 125px;
    }

    .mb-3 {
        margin-bottom: 1rem !important;
        width: auto;
    }
}

.card {
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.card-body {
    padding: 2rem;
    
}

.form-label {
    font-weight: bold;
    
}

.btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    font-weight: bold;
}

.btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
}

.alert-danger {
    margin-top: 1rem;
}

h2 {
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    font-weight: bold;
    
}

.icon {
    margin-right: 0.5rem;
}

h2.text-center.mb-4 {
    font-size: larger;
}
