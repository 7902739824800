/* src/styles/Precos.css */

html{
  background-color: #fff;
}

div{
  background-color: #fff;
}

.table-container {
    max-height: 600px; /* Define a altura máxima da tabela para permitir a rolagem */
    overflow-y: auto; 
    background-color: #fff;/* Adiciona uma barra de rolagem vertical */
  }
  
  .table thead th {
    position: sticky;
    top: 0;
    background-color: #f8f9fa;
    z-index: 1;
    font-weight: bold;
  }
  
  .table td, .table th {
    vertical-align: middle;
    text-align: left; /* Alinha o texto à esquerda para melhor leitura em dispositivos móveis */
  }
  
  .table-icon {
    font-size: 1.5rem;
    color: #007bff;
    margin-right: 8px; /* Espaço entre o ícone e o texto */
  }
  
  .description {
    text-align: left;
    white-space: pre-wrap; /* Permite quebras de linha nos textos */
  }
  
  /* Estilos responsivos */
  
  /* Dispositivos móveis (até 600px) */
  @media (max-width: 600px) {
    .table th, .table td {
      padding: 8px;
      font-size: 11px;
      background-color: #fff;
    }
  }
  
  /* Tablets (600px a 768px) */
  @media (min-width: 600px) and (max-width: 768px) {
    .table th, .table td {
      padding: 10px;
      font-size: 15px;
      margin-left: 10px;
      background-color: #fff;
    }
    
  }
  
  /* Notebooks (768px a 1024px) */
  @media (min-width: 768px) and (max-width: 1024px) {
    .table th, .table td {
      padding: 12px;
      font-size: 16px;
    }
  }
  
  /* Desktop (acima de 1024px) */
  @media (min-width: 1024px) {
    .table th, .table td {
      padding: 14px;
      font-size: 17px;
    }
  }
  