/* src/sisdok.css */

/* Estilos gerais para computadores */
body {
  font-family: 'Roboto', sans-serif;
  background-color: #f5f5f5;
  color: #333333;
  
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

.sisdok-container {
  
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  width: 100%;
  height: auto;
}

.form-container {
  flex: 1;
  padding-right: 20px;
}

.pdf-container {
  flex: 1;
  
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 680px;
  width: 100%;
}

h2 {
  color: #007bff;
  font-size: 2em;
  white-space: nowrap;
}

p {
  margin-bottom: 20px;
  font-size: 1.3em;
  padding-top: 25px;
}

label {
  display: block;
  text-align: left;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 1.2em;
}

input, select, button {
  width: calc(100% - 20px);
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 1.2em;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #03dac6;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.error {
  color: red;
  margin-top: 10px;
  font-size: 1.2em;
}

.loading {
  display: block;
  margin: 20px auto;
}

.pdf-download {
  text-align: right;
  margin-top: 10px;
}

canvas {
  border: 1px solid #ddd;
  border-radius: 5px;
  max-width: 100%;
  width: 100%; /* Garante que o canvas ocupe toda a largura disponível */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 30px; /* Aumentando o padding */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  /*max-width: 500px;
  text-align: left; /* Centralizando o texto */
}

.modal-content h3 {
  margin-top: 0;
  text-align: center;
  color: orangered;
  font-size: 2em;
}

.modal-content p {
  font-size: 1.2em;
  text-align: left; /* Justificando o texto */
}

.modal-content label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  color: orangered;
}

.modal-content input[type="checkbox"] {
  margin-right: 10px;
}

.modal-content button {
  margin-top: 20px;
}

.new-user-info{
  color: #007bff;
}
.new-user-info h3{
  color: #27df5e;
}
.user-input-fields{
  width: auto;
}




/* Media queries para dispositivos móveis */
@media (max-width: 767px) {
  body {
    height: auto; /* Permite que a altura do corpo se ajuste automaticamente */
    display: block; /* Remove o flex display para evitar problemas de alinhamento */
    justify-content: flex-start; /* Ajusta a justificação */
  }

  .sisdok-container {
    flex-direction: column; /* Alinha os elementos em coluna */
    padding: 20px; /* Ajusta o padding */
    max-width: fit-content; /* Ajusta a largura máxima */
    height: 640px; /* Ajusta a altura para auto */
  }

  .form-container {
    padding: 0; /* Remove o padding direito */
    width: 100%; /* Ajusta a largura */
  }

  .pdf-container {
    display: flex;
    margin-top: 2px; /* Adiciona um espaçamento superior */
    
    max-width: 350px; /* Ajusta a largura máxima */
    width: 200px; /* Garante que o container de PDF ocupe toda a largura disponível */
  }

  h2 {
    font-size: 1.5em; /* Ajusta o tamanho da fonte */
  }

  p {
    font-size: 1em; /* Ajusta o tamanho da fonte */
  }

  input, select, button {
    width: auto; /* Ajusta a largura */
    font-size: 1em; /* Ajusta o tamanho da fonte */
  }

  .modal-content {
    padding: 20px; /* Ajusta o padding */
    max-width: 90%; /* Ajusta a largura máxima */
  }

  .modal-content h3 {
    font-size: 1.5em; /* Ajusta o tamanho da fonte */
  }

  .modal-content p {
    font-size: 1em; /* Ajusta o tamanho da fonte */
  }

  canvas {
    width: 100%; /* Garante que o canvas ocupe toda a largura disponível */
    height: max-content; /* Ajusta a altura automaticamente */
  }

  /* Ajustes específicos para o contêiner do PDF */
  .rpv-core__viewer {
    display: flexbox;
    width: 100%; /* Garante que a largura seja 100% em dispositivos móveis */
    height: auto; /* Ajusta a altura automaticamente */
    max-width: 100%; /* Ajusta a largura máxima */
    margin: 15px; /* Centraliza o contêiner */
  }
  div {
    width: 400px;
    padding: 11px;
    margin-top: -10px;
  }
  img{
    display: flex;
        margin-top: 5px;
        max-width: 340px;
        width: max-content;
        margin: auto;

  
  }
 
  
}
